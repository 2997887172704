<template>
  <div class="">
    <DashboardNavbar />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <template v-if="hasData">
        <StudentHasDebtTable
          :usersData="users.usersData"
          :filterData="users.filterData"
          :defaultImg="users.user.defaultImg"
        />
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />
      <CustomPagination
        v-if="hasData"
        :paginationData="users.filterData"
        v-on:changePagination="changePagination($event)"
      />
    </div>
  </div>
</template>

<script>
import { USER_TYPE } from "./../../../utils/constantLists";
import { STATUS } from "./../../../utils/constants";
import DashboardNavbar from "./../../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../../components/general/ExceptionWithImg.vue";
import CustomPagination from "./../../../components/general/CustomPagination.vue";
import Users from "./../../../models/users/Users";
import apiUser from "./../../../api/user";
import generalMixin from "./../../../utils/generalMixin";
import generalSettingsMixin from "./../../../utils/generalSettingsMixin";
import StudentHasDebtTable from "./components/StudentHasDebtTable.vue";

export default {
  mixins: [generalMixin, generalSettingsMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    ExceptionWithImg,
    CustomPagination,
    StudentHasDebtTable,
  },
  computed: {
    hasData() {
      return this.users.usersData.length != 0;
    },
  },
  data() {
    return {
      users: new Users("students"),
    };
  },
  props: {
    userToken: {
      type: String,
      default: "",
    },
  },
  methods: {
    changePagination(pagination) {
      this.users.filterData.fillData(pagination);
      this.getAllUsers();
    },
    async getAllUsers() {
      this.isLoading = true;
      try {
        this.users.filterData.countDebtsNotFinishFrom = 1;
        this.users.filterData.userStatisticDataInclude = true;
        this.users.usersData = [];
        const response = await apiUser.getAll(this.users.filterData);
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.users.fillData(response.data);
      } catch (e) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
    async updateFilterData() {
      this.users.filterData.activationTypeTokens =
        this.generalSetting["students"].activationTypeTokens;
      this.users.filterData.userTypeToken = USER_TYPE.Student;
    },
  },
  async created() {
    await this.updateFilterData();
    await this.getAllUsers();
  },
};
</script>
